var scrollMonitor = require('scrollMonitor')



console.log(document.body.classList);
var pageTop = document.getElementById("page-top")
var header = document.getElementById("headerFixable")

var watcherOffset = header.offsetHeight

var stickyNavWatcher = scrollMonitor.create( pageTop, -watcherOffset )
stickyNavWatcher.lock()

stickyNavWatcher.exitViewport(function() {
  document.body.classList.add('js--header-fixed')
})

stickyNavWatcher.enterViewport(function() {
  document.body.classList.remove('js--header-fixed')
})
