var toggleClass = require('./helpers').toggleClass


// Get all page accordions

var pageAccordions = document.getElementsByClassName('Accordion')


// -----------------------------------------------------------------------------
// Util
// -----------------------------------------------------------------------------


function listenerLogic(item, selector) {
  item.querySelector(selector).addEventListener('click', function() {
    toggleClass(item, 'is-open')
  }, false)
}


function simpleListener(item) {
  item.addEventListener('click', function() {
    toggleClass(item, 'is-open')
  }, false)
}


// -----------------------------------------------------------------------------
// Set listeners
// -----------------------------------------------------------------------------


function setListeners() {

  // exit if no accordions on page
  if (!pageAccordions) {
    return
  }


  // Parent Items
  var current
  var accordionItems
  for (var i = 0; i < pageAccordions.length; i++) {
    (function() {

      current = pageAccordions[i]
      accordionItems = current.getElementsByClassName('Accordion-item')

      for (var j = 0; j < accordionItems.length; j++ ) {
        listenerLogic(accordionItems[j], '.Accordion-heading')
      }


    }())
  }


  // Child Items
  var childAccordionHeading = document.getElementsByClassName('Accordion-childHeading')
  var c
  for (var i = 0; i < childAccordionHeading.length; i++) {
    (function() {
      simpleListener(childAccordionHeading[i])
    }())
  }


  // Toggle Alls
  var toggleAllBtns = document.getElementsByClassName('js--Accordion-toggleAll')
  var t
  var tBtn
  for (var i = 0; i < toggleAllBtns.length; i++) {
    (function() {
      tBtn = toggleAllBtns[i]
      tBtn.addEventListener('click', function() {
        t = document.getElementById(this.getAttribute('data-targetID')).querySelectorAll('.Accordion-item')
        t.forEach(function(item) {
          toggleClass(item, 'is-open')
        })
      }, false)
    }())
  }

}

setListeners()
